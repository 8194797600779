<template>
  <ServicesDescription :isPageWrapper="true" @connectService="connectService">
    <template #title>
      Запись разговоров
    </template>
    <template #body>
      <p>
        <b>Запись разговоров </b>- инструмент, который позволит улучшить качество обслуживания
        клиентов.
      </p>
      <text-list :text="text"></text-list>
      <br />
      <div v-for="paragraph in description" :key="paragraph.title">
        <span>
          <b>
            {{ paragraph.title }}
          </b>
        </span>
        <br />
        <span>
          {{ paragraph.text }}
        </span>
        <br />
        <br />
      </div>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import TextList from '@/components/shared/TextList.vue';

export default {
  data() {
    return {
      text: [
        'Запись входящих и исходящих звонков',
        'Гибкие настройки записи разговоров отдельных сотрудников',
        'Доступ к записям разговоров в Личном кабинете',
        'Удобный поиск нужной записи'
      ],
      description: [
        {
          title: 'Узнайте, о чем говорит клиент',
          text:
            'Только при разговоре клиент может сказать, чего ему не хватает, высказать свои желания или опасения. Такая информация поможет оперативно принять решения в каком направлении следует развивать бизнес'
        },
        {
          title: 'Решайте спорные вопросы',
          text:
            'При возникновении конфликтов и отстаивании каждой из сторон своей точки зрения, запись совершенного разговора станет неоспоримым источником достоверной информации.'
        },
        {
          title: 'Учитесь на ошибках',
          text:
            'Наглядный пример - лучший способ понять, как стоит делать, а как нет. Успешные телефонные разговоры могут стать отличным примером для новых сотрудников, а провальные научить, как делать не стоит.'
        },
        {
          title: 'Совершенствуйтесь',
          text:
            'Запись разговоров может быть полезна не только для руководителя, но и для самого сотрудника. Прослушивание собственных разговоров поможет оценить себя со стороны как переговорщика и понять, над какими качествами еще необходимо поработать.'
        },
        {
          title: 'Не упускайте деталей',
          text:
            'Если при разговоре не удалось учесть детали, то повторное возвращение к разговору поможет расставить все на свои места и обновить информацию перед очередным разговором.'
        },
        {
          title: 'Контролируйте сотрудников',
          text:
            'Узнайте, кто соблюдает правила общения, работая по скриптам, а кому еще следует над этим поработать.'
        }
      ]
    };
  },

  components: {
    TextList,
    ServicesDescription
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'запись-разговоров' }
      });
    }
  }
};
</script>

<style></style>
