<template>
  <the-wrapper :isSpinner="isSpinner">
    <component :is="isConnected"></component>
  </the-wrapper>
</template>

<script>
import RecordsDescription from '@/components/Vpbx/call_record/RecordsDescription.vue';
import RecordsConnected from '@/components/Vpbx/call_record/RecordsConnected.vue';
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { mapMutations } from 'vuex';
import TheWrapper from '../../components/shared/TheWrapper.vue';

export default {
  data() {
    return {
      connected: false,
      isSpinner: true
    };
  },

  components: {
    RecordsDescription,
    RecordsConnected,
    TheWrapper
  },

  created() {
    getServiceData(URL.RECORD_CONNECTED)
      .then((result) => {
        this.connected = result;
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  computed: {
    isConnected() {
      return this.connected ? 'RecordsConnected' : 'RecordsDescription';
    }
  },

  methods: mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
};
</script>

<style></style>
