<template>
  <div :class="$style.table">
    <base-spinner
      v-if="loadingReport"
      :isRequestSpinner="true"
      :isBaseSpinner="false"
    ></base-spinner>
    <base-table
      v-else-if="!tableData.Error && !loadingReport"
      :fields="fields"
      :items="tableData"
      :per-page="filters.RecordsPerPage"
      :current-page="currentPage"
      :isFooter="isPagination"
    >
      <template #headerText>
        Список записей
      </template>
      <template #headerRight>
        <info-link :bigLink="bigLink" @clickLink="downloadAllRecords">
          <slot>
            Скачать записи текущей страницы
          </slot>
        </info-link>
      </template>
      <template #cell(StartTime)="{item}">
        {{ item.StartTime.replace(/T/, ' ') }}
      </template>
      <template #cell(Download)="{item}">
        <base-icon-button @click="downloadRecord(item)">
          <template #icon>
            <b-icon icon="download"></b-icon>
          </template>
        </base-icon-button>
      </template>
      <template #cell(Listen)="item">
        <base-icon-button @click="showRecordPlayer(item.toggleDetails, playRecord, item)">
          <template #icon>
            <b-icon icon="play-fill" scale="1.5"></b-icon>
          </template>
        </base-icon-button>
      </template>
      <template #row-details>
        <div :class="$style.audioWrap">
          <audio ref="audio" controls></audio>
        </div>
      </template>
      <template #footer>
        <b-pagination
          v-show="isPagintaion"
          v-model="currentPage"
          :total-rows="recordCount"
          :per-page="filters.RecordsPerPage"
          last-number
          align="center"
          size="Default"
          class="my-0"
          @input="lookCallsInPage()"
        ></b-pagination>
      </template>
    </base-table>
    <base-table v-else>
      <template #headerText>
        Список записей
      </template>
      <slot>
        <div>За указанный период Данных не найдено</div>
      </slot>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import InfoLink from '@/components/shared/InfoLink.vue';
import BaseSpinner from '@/components/shared/BaseSpinner.vue';
import BaseIconButton from '@/components/shared/buttons/BaseIconButton.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { downloadFile } from '@/api/common';
import { catchFunction, processResponse } from '@/additional_helpers/helpers';
import DOWNLOAD_URL from '@/basUrl';

export default {
  data() {
    return {
      fields: [
        { key: 'Download', label: '' },
        { key: 'Listen', label: '' },
        { key: 'StartTime', label: 'Начало' },
        { key: 'Duration', label: 'Длительн.' },
        { key: 'CallDirection', label: 'Направление' },
        { key: 'CallParties', label: 'Стороны' }
      ],
      currentPage: 1,
      isPagination: true,
      bigLink: false,
      audioUrl: ''
    };
  },

  components: {
    BaseTable,
    InfoLink,
    BaseIconButton,
    BaseSpinner
  },

  beforeUpdate() {
    // Сбрасываем страницу пагинации на 1
    if (this.loadingReport) this.currentPage = 1;
  },

  computed: {
    ...mapGetters({
      tableData: 'callRecords/tableData',
      filters: 'callRecords/filters',
      isEmpty: 'callRecords/isEmpty',
      loadingReport: 'loadingReport'
    }),

    isPagintaion() {
      const recordCount = this.tableData[0]?.RecordCount;
      const filterRecords = this.filters.RecordsPerPage;
      return recordCount && filterRecords && recordCount > filterRecords;
    },

    recordCount() {
      return this.tableData[0]?.RecordCount ?? 0;
    }
  },

  methods: {
    ...mapActions({ getRecords: 'callRecords/getRecords' }),

    ...mapMutations({
      SET_PAGE: 'callRecords/SET_PAGE',
      COLLAPSE_TABLE: 'callRecords/COLLAPSE_TABLE'
    }),

    lookCallsInPage() {
      this.SET_PAGE(this.currentPage);
      this.getRecords({ params: this.filters });
    },

    downloadRecord(record) {
      const row = JSON.stringify(record);
      downloadFile(DOWNLOAD_URL.RECORD_DOWNLOAD, row)
        .then((bytes) => this.createElementForDownload(bytes.text, `${record.StartTime}.mp3`))
        .catch((e) => catchFunction(e));
    },

    downloadAllRecords() {
      const row = JSON.stringify(this.filters);
      downloadFile(DOWNLOAD_URL.RECORD_DOWNLOAD_ALL, row)
        .then((bytes) => this.createElementForDownload(bytes.text, `${this.filters.StartDateTime}.zip`))
        .catch((e) => catchFunction(e));
    },

    createElementForDownload(bytes, fileName) {
      const elm = document.createElement('a'); // CREATE A LINK ELEMENT IN DOM
      elm.href = URL.createObjectURL(bytes); // SET LINK ELEMENTS CONTENTS
      elm.setAttribute('download', fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD. Params are after 'dowload'
      elm.click();
    },

    playRecord(record) {
      const row = JSON.stringify(record);

      downloadFile(DOWNLOAD_URL.RECORD_PLAY, row)
        .then((bytes) => {
          processResponse(bytes);
          this.$refs.audio.src = URL.createObjectURL(bytes.text);
          this.$refs.audio.play();
        })
        .catch((e) => catchFunction(e));
    },

    async showRecordPlayer(tooglePlayerFunc, playRecordFunc, item) {
      this.COLLAPSE_TABLE(item);
      await tooglePlayerFunc();
      // eslint-disable-next-line no-underscore-dangle
      if (item.item._showDetails) playRecordFunc(item.item);
    }
  }
};
</script>

<style lang="scss" module>
.table {
  width: 100%;
}

.audioWrap {
  padding-top: 0.5rem;
  width: 100%;

  & > * {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .table {
    margin-left: 1rem;
    width: calc(100% - 13.625rem);
  }
}
</style>
