<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <h2>Записи разговоров</h2>
      <br />
      <div class="dflex">
        <records-filter></records-filter>
        <records-table></records-table>
      </div>
    </slot>
  </the-wrapper>
</template>

<script>
import RecordsFilter from '@/components/Vpbx/call_record/RecordsFilter.vue';
import RecordsTable from '@/components/Vpbx/call_record/RecordsTable.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { ShowHours, ShowMinutes, ShowSeconds } from '@/additional_helpers/dates';
import { mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      filters: {
        CallDirection: 0,
        CallDuration: '00:00:00',
        CallDurationExpression: 0,
        PageNumber: 1,
        RecordsPerPage: 10,
        StartDateTime: `${new Date().toJSON().slice(0, 10)}T00:00:00`,
        EndDateTime: `${new Date()
          .toJSON()
          .slice(0, 10)}T${ShowHours()}:${ShowMinutes()}:${ShowSeconds()}`
      }
    };
  },

  components: {
    RecordsFilter,
    TheWrapper,
    RecordsTable
  },

  beforeRouteLeave(to, from, next) {
    this.SHOW_RECORDS('');
    next();
  },

  // Получает записи за текущий день
  created() {
    this.SET_FILTER(this.filters);
    this.getRecords({ vm: this, params: this.filters, loading: false });
  },

  methods: {
    ...mapMutations({
      SHOW_RECORDS: 'callRecords/SHOW_RECORDS',
      SET_FILTER: 'callRecords/SET_FILTER'
    }),

    ...mapActions({ getRecords: 'callRecords/getRecords' })
  }
};
</script>

<style module></style>
