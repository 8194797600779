<template>
  <b-input
    v-inputTimeMask
    :value="time"
    v-bind="$attrs"
    @input="updateTime"
    type="text"
    placeholder="00:00:00"
    class="input_color"
  ></b-input>
</template>

<script>
import { inputTimeMask } from '@/user_directives/inputTimeMask';

export default {
  inheritAttrs: false,

  props: {
    time: {
      type: [String, Number]
    }
  },

  directives: {
    inputTimeMask
  },

  methods: {
    updateTime(event) {
      this.$emit('input', event);
    }
  }
};
</script>

<style></style>
