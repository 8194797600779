import Vue from 'vue';

// Директива для поля ввода телефона
export const inputTimeMask = {
  bind(el) {
    // eslint-disable-next-line func-names
    // eslint-disable-next-line no-param-reassign
    el.oninput = function (e) {
      if (!e.isTrusted) {
        return;
      }

      const x = this.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,2})/);

      // eslint-disable-next-line prefer-template
      if (x[1] > 23) x[1] = 23;
      if (x[2] > 60) x[2] = 59;
      if (x[3] > 60) x[3] = 59;
      this.value = (x[1] ? `${x[1]}` : '') + (x[2] ? `:${x[2]}` : '') + (x[3] ? `:${x[3]}` : '');
    };
  }
};

export default inputTimeMask;
Vue.directive('inputTimeMask', inputTimeMask);
