<template>
  <div :class="$style.filtersWrap">
    <base-table :isVisible="tableVisible">
      <template #headerText> Фильтры поиска</template>
      <template #additional>
        <b-form
          style="display: flex; flex-direction: column; width: 100%;"
          @submit.prevent="getRecordsByFilterSettings()"
        >
          <b-form-group
            v-for="item in filterDate"
            :key="item.label"
            :label="item.label"
            :class="$style.filterLabel"
          >
            <BaseInputDate v-model="item.date"/>
            <br />
            <input-time v-model="item.modelTime"></input-time>
          </b-form-group>
          <b-form-group :class="$style.filterLabel" label="Номер телефона">
            <BaseTextInput v-model="phoneNumber" />
          </b-form-group>
          <b-form-group :class="$style.filterLabel" label="Направление звонка">
            <BaseSelect v-model="callDirection" :options="filterParams.fDirections"/>
          </b-form-group>
          <b-form-group :class="$style.filterLabel" label="Длительность">
            <BaseSelect
              v-model="callDurationSign"
              :options="filterParams.fDurations"
            />
            <br />
            <br />
            <input-time v-model="callDuration"></input-time>
          </b-form-group>
          <b-form-group :class="$style.filterLabel" label="Записей на странице">
            <BaseSelect
              v-model="RecordsPerPage"
              :options="filterParams.fCountPerPage"
            />
          </b-form-group>
          <BaseButton @click.stop.prevent="getRecordsByFilterSettings" :disabled="isDisabled">
            <template #icon>
              <b-icon icon="search" scale="0.8"></b-icon>
            </template>
            <template #text>
              Искать
            </template>
          </BaseButton>
        </b-form>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import InputTime from '@/components/shared/inputs/InputTime.vue';
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import { ShowHours, ShowMinutes, ShowSeconds } from '@/additional_helpers/dates';
import URL from '@/basUrl';
import { mapActions, mapMutations } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';

export default {
  data() {
    return {
      tableVisible: false,
      filterParams: {},
      phoneNumber: '',
      callDirection: 0,
      callDuration: '',
      callDurationSign: 0,
      RecordsPerPage: 10,
      filterDate: [
        {
          label: 'Начало интервала',
          date: new Date().toJSON().slice(0, 10),
          modelTime: '',
          type: 'date'
        },
        {
          label: 'Конец интервала',
          date: new Date().toJSON().slice(0, 10),
          modelTime: `${ShowHours()}:${ShowMinutes()}:${ShowSeconds()}`,
          type: 'date'
        }
      ]
    };
  },

  components: {
    BaseTable,
    BaseButton,
    InputTime,
    BaseSelect,
    BaseTextInput,
    BaseInputDate
  },

  created() {
    getServiceData(URL.CALL_RECORDS)
      .then((result) => {
        this.filterParams = result;
      })
      .catch((e) => catchFunction(e));
  },

  mounted() {
    ShowHours();
    ShowMinutes();
    ShowSeconds();
  },

  computed: {
    // Преобразует инпуты времени для отправки на сервер
    filterTime() {
      this.filterDate.forEach((el, index) => {
        // Если не указано время, то устанавливается 00:00:00
        if (!el.modelTime.length) {
          this.filterDate[index].modelTime = '00:00:00';
          return el.modelTime.slice(0, el.modelTime.length - 1);
        }
        // если указано в формате 05:59, то преобразуется в 05:59:00
        if (el.modelTime.length < 6) {
          this.filterDate[index].modelTime = `${el.modelTime}:00`;
          return this.filterDate[index].modelTime;
        }
        return el.modelTime;
      });
      return this.filterDate;
    },

    // Преобразуется длительность звонка для отправки на сервер
    duration() {
      // если не указана, то устанавливается как 00:00:00
      if (!this.callDuration.length) return '00:00:00';
      // если указана, формате 05:59, то преобразуется в 05:59:00
      if (this.callDuration.length === 5) {
        return `${this.callDuration}:00`;
      }
      return this.callDuration;
    },

    // отключает кнопку "Искать", если не полностью заполнены: время начала/конца и длительность
    isDisabled() {
      if (
        this.filterDate
          .map((el) => el.modelTime.length > 0 && el.modelTime.length < 5)
          .includes(true)
        || (this.callDuration.length > 0 && this.callDuration.length < 5)
        || (this.callDuration.length > 5 && this.callDuration.length < 8)
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    ...mapMutations({
      SET_FILTER: 'callRecords/SET_FILTER',
      SHOW_LOADING_SPINNER: 'SHOW_LOADING_SPINNER'
    }),

    ...mapActions({ getRecords: 'callRecords/getRecords' }),

    getRecordsByFilterSettings() {
      const requestData = {
        PhoneNumberPart: +this.phoneNumber,
        CallDirection: this.callDirection,
        CallDuration: this.duration,
        CallDurationExpression: this.callDurationSign,
        PageNumber: 1,
        RecordsPerPage: this.RecordsPerPage,
        StartDateTime: `${this.filterTime[0].date}T${this.filterTime[0].modelTime}`,
        EndDateTime: `${this.filterTime[1].date}T${this.filterTime[1].modelTime}`
      };

      if (requestData.StartDateTime > requestData.EndDateTime) {
        const message = 'Дата начала интервала не может быть больше даты конца интервала';
        catchFunction(message);
        return;
      }

      this.SHOW_LOADING_SPINNER(true);
      this.SET_FILTER(requestData);
      this.getRecords({ vm: this, params: requestData });
    }
  }
};
</script>

<style module>
.filterLabel {
  font-weight: 600;
}

.filtersWrap {
  width: 100%;
}

@media (min-width: 1200px) {
  .filtersWrap {
    max-width: 12.625rem;
  }
}
</style>
